import { createSlice } from "@reduxjs/toolkit";

const accounts_slice = createSlice({
  name: "accounts",
  initialState: {
    accounts: [],
  },
  reducers: {
    addAccountsToStore: (state, action) => {
      state.accounts = action.payload;
    },
    removeAccountFromStore: (state, action) => {
      state.accounts = state.accounts.filter(
        (account) => account.id !== action.payload.id
      );
    },
  },
});

export const { addAccountsToStore, removeAccountFromStore } =
  accounts_slice.actions;

export default accounts_slice.reducer;
