import { configureStore } from "@reduxjs/toolkit";

import subscriptionsReducer from "./slices/subscriptions";
import usersReducer from "./slices/user";
import unitsReducer from "./slices/unit";
import plansReducer from "./slices/plans";
import transactionsReducer from "./slices/transactions";
import accountsReducer from "./slices/account";
import adminsReducer from "./slices/admins";
import organizationReducer from "./slices/organization";
import authReducer from "./slices/auth";

export const store = configureStore({
  reducer: {
    plans: plansReducer,
    subscriptions: subscriptionsReducer,
    transactions: transactionsReducer,
    users: usersReducer,
    units: unitsReducer,
    accounts: accountsReducer,
    admins: adminsReducer,
    organization: organizationReducer,
    auth: authReducer,
  },
});
