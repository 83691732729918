import { createSlice } from "@reduxjs/toolkit";

const transactions_slice = createSlice({
  name: "transactions",
  initialState: {
    invoices: [],
    history: [],
  },
  reducers: {
    addInvoicesToStore: (state, action) => {
      state.invoices = action.payload;
    },
    addHistoryToStore: (state, action) => {
      state.history = action.payload;
    },
    updateTransactionStatus: (state, action) => {
      const { id, new_status } = action.payload;
      const transaction_index = state.history.findIndex(
        (transaction) => transaction.id === id
      );

      if (transaction_index !== -1) {
        state.history[transaction_index].status = new_status;
      }
    },
  },
});

export const {
  addInvoicesToStore,
  addHistoryToStore,
  updateTransactionStatus,
} = transactions_slice.actions;

export default transactions_slice.reducer;
