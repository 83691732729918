import { createSlice } from "@reduxjs/toolkit";

const subscriptions_slice = createSlice({
  name: "subscriptions",
  initialState: {
    subscriptions: [],
    licenses: [],
  },
  reducers: {
    addSubscriptionsToStore: (state, action) => {
      state.subscriptions = action.payload;
    },
    addSubscriptionLicensesToStore: (state, action) => {
      state.licenses = action.payload;
    },
    removeSubscriptionLicenseFromStore: (state, action) => {
      state.licenses = state.licenses.filter(
        (license) => !action.payload.some((l) => l.user_id === license.user_id)
      );
    },

    updateSubscriptionStatusInStore: (state, action) => {
      const { id, status } = action.payload;
      const subscriptionIndex = state.subscriptions.findIndex(
        (sub) => sub.id === id
      );

      if (subscriptionIndex !== -1) {
        state.subscriptions[subscriptionIndex].status = status;
      }
    },
  },
});

export const {
  addSubscriptionsToStore,
  addSubscriptionLicensesToStore,
  removeSubscriptionLicenseFromStore,
  updateSubscriptionStatusInStore,
} = subscriptions_slice.actions;

export default subscriptions_slice.reducer;
