import { createSlice } from "@reduxjs/toolkit";

const admins_slice = createSlice({
  name: "admins",
  initialState: {
    admins: [],
  },
  reducers: {
    addAdminsToStore: (state, action) => {
      state.admins = action.payload;
    },
    removeAdminFromStore: (state, action) => {
      state.admins = state.admins.filter(
        (account) => account.id !== action.payload.id
      );
    },
  },
});

export const { addAdminsToStore, removeAdminFromStore } = admins_slice.actions;

export default admins_slice.reducer;
