import { createSlice } from "@reduxjs/toolkit";

const auth_slice = createSlice({
  name: "auth",
  initialState: {
    session_expired_signal: false,
    forbidden_signal: false,
  },
  reducers: {
    setSessionExpiredSignal: (state, action) => {
      state.session_expired_signal = action.payload;
    },
    setForbiddenSignal: (state, action) => {
      state.forbidden_signal = action.payload;
    },
  },
});

export const { setSessionExpiredSignal, setForbiddenSignal } =
  auth_slice.actions;

export const sessionExpiredSignal = (state) =>
  state.auth.session_expired_signal;

export const forbiddenSignal = (state) => state.auth.forbidden_signal;

export default auth_slice.reducer;
