import { createSlice } from "@reduxjs/toolkit";

const plans_slice = createSlice({
  name: "plans",
  initialState: {
    plans: {},
    switch_id: "",
    subscribed_id: "",
    skus: [],
  },
  reducers: {
    addPlansToStore: (state, action) => {
      state.plans = action.payload;
    },
    addSwitchIdToStore: (state, action) => {
      state.switch_id = action.payload;
    },
    addSubscribedIdToStore: (state, action) => {
      state.subscribed_id = action.payload;
    },
    addSkusToStore: (state, action) => {
      state.skus = action.payload;
    },
  },
});

export const {
  addPlansToStore,
  addSubscribedIdToStore,
  addSwitchIdToStore,
  addSkusToStore,
} = plans_slice.actions;

export default plans_slice.reducer;
