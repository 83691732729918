import { createSlice } from "@reduxjs/toolkit";

const users_slice = createSlice({
  name: "users",
  initialState: {
    users: [],
    user: {},
    licensed: [],
    unlicensed: [],
  },
  reducers: {
    addUsersToStore: (state, action) => {
      state.users = action.payload;
    },
    addOneUserToStore: (state, action) => {
      state.users.unshift(action.payload);
    },
    addUserDetailsToStore: (state, action) => {
      state.user = action.payload;
    },
    removeOneUserFromStore: (state, action) => {
      state.users = state.users.filter((user) => user.id !== action.payload.id);
    },

    updateOneUserInStore: (state, action) => {
      const updatedUser = action.payload;
      state.users = state.users.map((user) =>
        user.id === updatedUser.id ? updatedUser : user
      );
    },

    // licensed reducers
    addUserLicensedDataToStore: (state, action) => {
      state.licensed = action.payload;
    },

    //unlicensed reducers
    addUserUnLicensedDataToStore: (state, action) => {
      state.unlicensed = action.payload;
    },
  },
});

export const {
  addOneUserToStore,
  addUsersToStore,
  addUserDetailsToStore,
  addUserLicensedDataToStore,
  addUserUnLicensedDataToStore,
  removeOneUserFromStore,
  updateOneUserInStore,
} = users_slice.actions;

export default users_slice.reducer;
