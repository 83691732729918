import { createSlice } from "@reduxjs/toolkit";

const organization_slice = createSlice({
  name: "organization",
  initialState: {
    organization: {},
  },
  reducers: {
    addOrganizationToStore: (state, action) => {
      state.organization = action.payload;
    },
    updateOrganizationName: (state, action) => {
      state.organization = { ...state.organization, name: action.payload };
    },
    updateOrganizationContacts: (state, action) => {
      state.organization = {
        ...state.organization,
        phone: action.payload.phone,
        owner_email: action.payload.owner_email,
      };
    },
    updateDprAgreement: (state, action) => {
      state.organization = {
        ...state.organization,
        dpr_agreement_date: action.payload.dpr_agreement_date,
      };
    },
  },
});

export const {
  addOrganizationToStore,
  updateOrganizationName,
  updateOrganizationContacts,
  updateDprAgreement,
} = organization_slice.actions;

export default organization_slice.reducer;
