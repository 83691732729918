import { createSlice } from "@reduxjs/toolkit";

const units_slice = createSlice({
  name: "units",
  initialState: {
    units: {},
  },
  reducers: {
    addUnitsToStore: (state, action) => {
      state.units = action.payload;
    },
    addOneUnitToStore: (state, action) => {
      state.units.unshift(action.payload);
    },
    removeOneUnitFromStore: (state, action) => {
      state.units = state.units.filter((unit) => unit.id !== action.payload.id);
    },
    updateOneUnitInStore: (state, action) => {
      const unitIndex = state.units.findIndex(
        (unit) => unit.id === action.payload.id
      );

      state.units[unitIndex] = action.payload;
    },
  },
});

export const {
  addUnitsToStore,
  addOneUnitToStore,
  removeOneUnitFromStore,
  updateOneUnitInStore,
} = units_slice.actions;

export default units_slice.reducer;
